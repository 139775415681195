import UserCookie from './UserCookie';
import UserType from "../model/UserType";

class User implements UserType{
    public cookie;

    constructor() {
        this.cookie = new UserCookie();
    }

    isLogged(): boolean {
        const value = this.cookie.getData();

        return value === "1";
    }

    login(): void {
        this.cookie.setData("1", 0);
    }

    logout(): void {
        this.cookie.remove();
    }
}

export default User;
