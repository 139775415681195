import CookieType from "../model/CookieType";

class Cookie implements CookieType {
    public name;
    public data;
    public options;

    constructor(name: string) {
        this.name = name;

        if (this.name === '') {
            return;
        }

        this.data = (this.retrieve() || '');
        this.options = {
            domain: global.location.hostname.match(/(?:https?:\/\/)?(?:[^@\n]+@)?(?:\w+\.)?([^:\/\n]+\.[a-z]{2,})/i)[1],
            path: '/',
            secure: (global.location.protocol.match('^https') !== null),
        };
    }

    retrieve(): string {
        const start = document.cookie.indexOf(`${this.name}=`);

        if (start === -1 || this.name !== document.cookie.substr(start, this.name.length)) {
            return '';
        }

        const len = start + this.name.length + 1;
        let end = document.cookie.indexOf(';', len);

        if (end === -1) {
            end = document.cookie.length;
        }

        return unescape(document.cookie.substring(len, end));
    }

    getData(): string {
        return this.data;
    }

    setData(value: string, months: number): void {
        this.data = value;
        this.store(months);
    }

    store(months: number): void {
        document.cookie = `${this.name}=${encodeURIComponent(this.data)
        }${this.getOptions()
        }${Cookie.generateExpires(months)}`;
    }

    remove(): void {
        document.cookie = `${this.name}=${this.getOptions()};expires=${(new Date(0)).toUTCString()}`;
    }

    static generateExpires = (months: number): string => {
        if (months <= 0) {
            return '';
        }

        const today = new Date();
        const expires = new Date(today.getFullYear(), today.getMonth() + months, 1, 0, 0);
        return `;expires=${expires.toUTCString()}`;
    };

    getOptions(): string {
        return (this.options?.path ? `;path=${this.options.path}` : '')
            + (this.options?.domain ? `;domain=${this.options.domain}` : '')
            + (this.options?.secure ? ';secure' : '');
    }
}

export default Cookie;
