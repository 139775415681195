export default class DocumentStatusResolver {
    public isNotFoundStatus = async (): Promise<boolean> => {
        const navigationData: any = typeof window.performance.getEntriesByType === "function"
            ? window.performance.getEntriesByType('navigation')[0]
            : null;

        if (Number.isInteger(navigationData?.responseStatus)) {
            return navigationData.responseStatus === 404;
        }

        const controller = new AbortController();
        const timeout = setTimeout(() => controller.abort(), 5000);

        try {
            const response = await fetch(window.location.href, {
                signal: controller.signal,
            });

            clearTimeout(timeout);

            return response.status === 404;
        } catch (error) {
            return false;
        }
    }
}