import StatDataType from "../model/StatDataType";

class ApiClient {
    url: string;

    constructor() {
        this.url = process.env.PUBLISHER_URL;
    }

    sendStats(id: string, data: StatDataType): boolean {
        return navigator.sendBeacon(`${this.url}stats/save`, JSON.stringify(this.normalize(id, data)))
    }

    private normalize(id: string, data: StatDataType) {
        const normalizedData = {
            i: id,
            t: data.token,
            p: data.platform,
            v: data.visit,
            r: data.referrer,
            l: data.logged,
            u: data.userId,
            ur: data.uri,
            c: {
                ty: data.content.type,
                t: data.content.title,
                c: data.content.category,
                i: data.content.image,
                a: data.content.author,
                p: data.content.published,
                m: data.content.modified,
            },
            lo: data.locale,
        }

        if (data.readingTime) {
            Object.assign(normalizedData, {
                pv: {
                    i: data.readingTime.pageView,
                    t: data.readingTime.time,
                }
            })
        }

        if (data.campaign) {
            Object.assign(normalizedData, {
                ca: data.campaign
            })
        }

        return normalizedData
    }
}

export default ApiClient;
