export default interface JsonLdType {
    "@type"?: string
    type?: string
    headline?: string
    image?: Image|string[]
    articleSection?: string|string[]
    author?: Author|Author[]
    datePublished?: string
    dateModified?: string
}

interface Image {
    url?: string
}

interface Author {
    name?: string
}

export const articleSchemaTypes = [
    'NewsArticle',
    'AdvertiserContentArticle',
    'Report',
    'SatiricalArticle',
    'ScholarlyArticle',
    'SocialMediaPosting',
    'TechArticle',
    'AnalysisNewsArticle',
    'AskPublicNewsArticle',
    'BackgroundNewsArticle',
    'OpinionNewsArticle',
    'ReportageNewsArticle',
    'ReviewNewsArticle',
];